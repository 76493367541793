import React from "react"
// import { Image } from "semantic-ui-react"
//import Image from "gatsby-image"
import { covidupd } from "../../data/campus"
import Layout from "../../components/layout"
import GeneralComp from "../../components/generalComp"
import SEO from "../../components/seo"
import { getWords } from "../../helper-functions"
const CovidMeasures = props => {
  return (
    <div>
      <Layout>
        <SEO
          title={covidupd.title}
          description={getWords(covidupd.content, 60)}
        />
        <GeneralComp {...covidupd} />
      </Layout>
    </div>
  )
}

export default CovidMeasures
